import {
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsLeftRight,
  faChevronLeft,
  faCopy,
  faEraser,
  faFill,
  faPlusMinus,
  faTrash,
  faUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import { faVacuum } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButtons, IonRange } from "@ionic/react";
import { Col, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import ColorPicker from "../ColorPicker";
import IconButton from "../IconButton";
import "./ToolBar.css";

const ToolBar = ({
  colors,
  layerSelected,
  setColors,
  setLayerSelected,
  setToolSelected,
  setToolbarState,
  svg,
  toolSelected,
  toolbarState,
  updateSvg,
  eraserRadius,
  vaccumRadius,
  setEraserRadius,
  setVaccumRadius,
}) => {
  const [dragging, setDragging] = useState(false);
  useEffect(() => {}, [toolSelected]);

  // Helper function to get the current radius based on tool
  const getCurrentRadius = () => {
    return toolSelected === "erase" ? eraserRadius : vaccumRadius;
  };

  // Helper function to get the current radius setter based on tool
  const getCurrentRadiusSetter = () => {
    return toolSelected === "erase" ? setEraserRadius : setVaccumRadius;
  };

  return (
    <>
      {toolbarState === "default" && (
        <>
          <Row
            justify="center"
            style={{ paddingTop: "5px", paddingBottom: "40px", width: "100%" }}
          >
            {layerSelected === "all" && (
              <IonButtons text>
                Select a layer to edit
                <FontAwesomeIcon
                  icon={faArrowUp}
                  size={"sm"}
                  style={{ padding: "9px" }}
                />
              </IonButtons>
            )}
            {layerSelected !== "all" && (
              <ion-scroll
                class="toolbarDiv"
                style={{
                  maxWidth: "100%",
                  overflowX: "scroll",
                  overflowY: "hidden",
                }}
              >
                <Row
                  wrap={false}
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <IconButton
                    onClickHandler={() => {
                      setToolSelected("move");
                    }}
                    isSelected={toolSelected === "move" ? true : false}
                    icon={faUpDownLeftRight}
                    text="Pan"
                  />
                  <IconButton
                    onClickHandler={() => {
                      setToolSelected("cut");
                    }}
                    icon={faPlusMinus}
                    text="Fill-Cut"
                    textSize="small"
                    isSelected={toolSelected === "cut" ? true : false}
                  />
                  <IconButton
                    onClickHandler={() => {
                      setToolSelected("erase");
                      setToolbarState("erase");
                    }}
                    icon={faEraser}
                    text="Erase"
                    isSelected={toolSelected === "erase" ? true : false}
                  />
                  <IconButton
                    onClickHandler={() => {
                      setToolSelected("vacuum");
                      setToolbarState("vacuum");
                    }}
                    icon={faVacuum}
                    text="Vacuum"
                    isSelected={toolSelected === "vacuum" ? true : false}
                  />
                  <IconButton
                    onClickHandler={() => {
                      let largestBox = 0;
                      let largestBoxIndex = 0;
                      let svgCopy = JSON.parse(JSON.stringify(svg));
                      let index = layerSelected;
                      let layer = svgCopy.children[index];
                      for (let i = 0; i < layer.children.length; i++) {
                        let box =
                          layer.children[i].width * layer.children[i].height;
                        if (box > largestBox) {
                          largestBox = box;
                          largestBoxIndex = i;
                        }
                      }
                      layer.children.splice(0, largestBoxIndex);
                      layer.children.splice(1, layer.children.length - 1);
                      updateSvg(svgCopy);
                    }}
                    icon={faFill}
                    text="Auto-Fill"
                  />
                  <ColorPicker
                    svg={svg}
                    updateSvg={updateSvg}
                    colors={colors}
                    layerSelected={layerSelected}
                    setColors={setColors}
                  />
                  <IconButton
                    onClickHandler={() => {
                      setToolbarState("reorder");
                    }}
                    icon={faArrowsLeftRight}
                    text="Order"
                    isSelected={toolSelected === "reorder" ? true : false}
                  />
                  <IconButton
                    onClickHandler={() => {
                      var svgCopy = JSON.parse(JSON.stringify(svg));
                      let index = layerSelected;
                      svgCopy.children.splice(
                        index,
                        0,
                        svgCopy.children[index]
                      );
                      updateSvg(svgCopy);
                      var colorsCopy = JSON.parse(JSON.stringify(colors));
                      colorsCopy.splice(index, 0, colorsCopy[index]);
                      setColors(colorsCopy);
                    }}
                    icon={faCopy}
                    text="Copy"
                    isSelected={toolSelected === "duplicate" ? true : false}
                  />
                  <IconButton
                    onClickHandler={() => {
                      let svgCopy = JSON.parse(JSON.stringify(svg));
                      let index = layerSelected;
                      svgCopy.children.splice(index, 1);
                      updateSvg(svgCopy);
                      setLayerSelected("all");
                      let colorsCopy = JSON.parse(JSON.stringify(colors));
                      colorsCopy.splice(index, 1);
                      setColors(colorsCopy);
                    }}
                    icon={faTrash}
                    text="Delete"
                  />
                </Row>
              </ion-scroll>
            )}
          </Row>
        </>
      )}

      {toolbarState === "reorder" && (
        <Row>
          <Col flex="100px">
            <Row
              justify="center"
              onClick={() => {
                setToolbarState("default");
              }}
              style={{
                paddingTop: "5px",
                paddingBottom: "40px",
                width: "100%",
              }}
            >
              <IconButton icon={faChevronLeft} text="" />
            </Row>
          </Col>
          <Col flex="auto">
            <Row
              justify="center"
              style={{
                paddingTop: "5px",
                paddingBottom: "40px",
                width: "calc(100%)",
                marginLeft: "-50px",
              }}
            >
              <IconButton
                disabled={layerSelected === 0}
                icon={faArrowLeft}
                text=""
                onClickHandler={() => {
                  let svgCopy = JSON.parse(JSON.stringify(svg));
                  let index = layerSelected;
                  if (index !== 0) {
                    [svgCopy.children[index], svgCopy.children[index - 1]] = [
                      svgCopy.children[index - 1],
                      svgCopy.children[index],
                    ];
                    updateSvg(svgCopy);
                    setLayerSelected(index - 1);
                    let colorsCopy = JSON.parse(JSON.stringify(colors));
                    [colorsCopy[index], colorsCopy[index - 1]] = [
                      colorsCopy[index - 1],
                      colorsCopy[index],
                    ];
                    setColors(colorsCopy);
                  }
                }}
              />
              <IconButton
                disabled={layerSelected === svg.children.length - 1}
                icon={faArrowRight}
                text=""
                onClickHandler={() => {
                  let svgCopy = JSON.parse(JSON.stringify(svg));
                  let index = layerSelected;
                  if (index !== svgCopy.children.length - 1) {
                    [svgCopy.children[index], svgCopy.children[index + 1]] = [
                      svgCopy.children[index + 1],
                      svgCopy.children[index],
                    ];
                    updateSvg(svgCopy);
                    setLayerSelected(index + 1);
                  }
                }}
              />
            </Row>
          </Col>
        </Row>
      )}

      {(toolbarState === "vacuum" || toolbarState === "erase") && (
        <Row>
          <Col flex="100px">
            <Row
              justify="center"
              onClick={() => {
                setToolbarState("default");
                setToolSelected("move");
              }}
              style={{
                paddingTop: "5px",
                paddingBottom: "40px",
                width: "100%",
              }}
            >
              <IconButton icon={faChevronLeft} text="" />
            </Row>
          </Col>
          <Col flex="auto">
            <Tooltip
              title={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <svg
                    width={getCurrentRadius()}
                    height={getCurrentRadius()}
                    style={{ backgroundColor: "white" }}
                  >
                    <rect
                      width={getCurrentRadius() * 2}
                      height={getCurrentRadius() * 2}
                      fill="white"
                    />
                  </svg>
                </div>
              }
              placement="top"
              arrow={false}
              open={dragging}
              onClose={() => {
                setDragging(false);
              }}
            >
              <IonRange
                onBlur={() => {
                  setDragging(false);
                }}
                style={{ marginTop: "-5px", maxWidth: "calc(100% - 100px)" }}
                min={10}
                max={100}
                step={1}
                value={getCurrentRadius()}
                onIonChange={async (e) => {
                  getCurrentRadiusSetter()(e.detail.value);
                  setDragging(true);
                }}
              >
                {toolbarState === "erase" && (
                  <>
                    <FontAwesomeIcon slot="start" icon={faEraser} size="xs" />
                    <FontAwesomeIcon slot="end" icon={faEraser} />
                  </>
                )}
              </IonRange>
            </Tooltip>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ToolBar;
