import { useContext, useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonIcon, IonSpinner, IonToggle } from "@ionic/react";
import { Button, Modal } from "antd";
import { closeOutline, ellipsisVertical } from "ionicons/icons";

// Assets
import deleteIcon from "../../../assets/delete_icon.svg";
import deleteHoverIcon from "../../../assets/delete_icon_hover.svg";
import downloadIcon from "../../../assets/download_icon.svg";
import downloadHoverIcon from "../../../assets/download_icon_hover.svg";

// Context and Services
import AppContext from "../../../contexts/AppContext";
import {
  deleteFileFromStorage,
  downloadSVGFromStorage,
  incrementViewOrDownloadCount,
} from "../../../lib/firebase/firebase";

// Redux Actions
import { setShowEditor } from "../../../redux/slices/MyFiles";
import { deleteUploadedFileInRedux } from "../../../redux/slices/UploadedFiles";

// Styles
import "./styles.css";

const FileCard = ({ file, index, onImageLoaded }) => {
  // State
  const [overlayState, setOverlayState] = useState({
    isActive: false,
    showDeleteAlert: false,
    isDeleting: false,
  });
  const [hoverStates, setHoverStates] = useState({
    deleteIcon: false,
    downloadIcon: false,
  });
  const [imageLoaded, setImageLoaded] = useState(false);

  // Refs and Context
  const cardRef = useRef(null);
  const { setSvgStore } = useContext(AppContext);

  // Redux
  const dispatch = useDispatch();
  const currentTab = useSelector((state) => state.currentTab.tab);

  // Click Outside Handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!overlayState.isActive || overlayState.showDeleteAlert) return;

      const modalContent = document.querySelector(".ant-modal-content");
      const isOutsideCard =
        cardRef.current && !cardRef.current.contains(event.target);
      const isOutsideModal =
        !modalContent || !modalContent.contains(event.target);

      if (isOutsideCard && isOutsideModal) {
        setOverlayState((prev) => ({ ...prev, isActive: false }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [overlayState.isActive, overlayState.showDeleteAlert]);

  // Handlers
  const toggleOverlay = useCallback(() => {
    setOverlayState((prev) => ({
      ...prev,
      isActive: !prev.isActive,
    }));
  }, []);

  const handleDeleteFile = useCallback(async () => {
    try {
      setOverlayState((prev) => ({ ...prev, isDeleting: true }));
      await deleteFileFromStorage(file.uri);
      dispatch(deleteUploadedFileInRedux({ id: file.id }));
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      setOverlayState((prev) => ({
        ...prev,
        isDeleting: false,
        showDeleteAlert: false,
      }));
    }
  }, [dispatch, file.uri, file.id]);

  const handleDownload = useCallback(async () => {
    try {
      const url = await downloadSVGFromStorage(file.uri);
      const svgString = await (await fetch(url)).text();

      const blob = new Blob([svgString], { type: "image/svg+xml" });
      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);

      await incrementViewOrDownloadCount(file.id, "download");
    } catch (error) {
      console.error("Error downloading SVG:", error);
    }
  }, [file.uri, file.name, file.id]);

  const handleImageLoad = useCallback(() => {
    setImageLoaded(true);
    onImageLoaded?.();
  }, [onImageLoaded]);

  const handleEditorOpen = useCallback(() => {
    dispatch(setShowEditor({ currentFile: file }));
    setSvgStore({ firebase: file.uri });
  }, [dispatch, setSvgStore, file]);

  // Render Helpers
  const renderOverlayContent = () => (
    <div className="overlay-content">
      {currentTab !== "community" && (
        <div
          className="nft__item_action nft__item_action--margin-bottom"
          onClick={() =>
            setOverlayState((prev) => ({ ...prev, showDeleteAlert: true }))
          }
          onMouseEnter={() =>
            setHoverStates((prev) => ({ ...prev, deleteIcon: true }))
          }
          onMouseLeave={() =>
            setHoverStates((prev) => ({ ...prev, deleteIcon: false }))
          }
        >
          <img
            src={hoverStates.deleteIcon ? deleteHoverIcon : deleteIcon}
            alt="Delete"
            className="overlay-icon"
          />
          <span>Delete</span>
        </div>
      )}

      <div
        className="nft__item_action nft__item_action--margin-bottom"
        onClick={handleDownload}
        onMouseEnter={() =>
          setHoverStates((prev) => ({ ...prev, downloadIcon: true }))
        }
        onMouseLeave={() =>
          setHoverStates((prev) => ({ ...prev, downloadIcon: false }))
        }
      >
        <img
          src={hoverStates.downloadIcon ? downloadHoverIcon : downloadIcon}
          alt="Download"
          className="overlay-icon"
        />
        <span>Download</span>
      </div>
    </div>
  );

  return (
    <div className="custom-card" ref={cardRef}>
      <div className="nft__item" style={{ height: "175px", margin: 0 }}>
        {overlayState.isActive && (
          <div className="overlay">
            <div className="close-btn" onClick={toggleOverlay}>
              <IonIcon size="small" icon={closeOutline} slot="end" />
            </div>
            {renderOverlayContent()}
          </div>
        )}

        <div className="flex-end">
          <div className="icon-wrapper">
            <IonIcon
              size="small"
              cursor="pointer"
              icon={ellipsisVertical}
              slot="end"
              onClick={toggleOverlay}
            />
          </div>
        </div>

        <div className="nft__item_wrap" style={{ height: "178px" }}>
          <div className="image_div">
            {!imageLoaded && <IonSpinner name="circular" />}
            <img
              style={{
                maxHeight: "158px",
                display: imageLoaded ? "block" : "none",
                width: "100%",
              }}
              src={file.url}
              onClick={handleEditorOpen}
              alt={`Uploaded ${index}`}
              onLoad={handleImageLoad}
            />
          </div>
        </div>

        <Modal
          open={overlayState.showDeleteAlert}
          centered
          onCancel={() =>
            setOverlayState((prev) => ({ ...prev, showDeleteAlert: false }))
          }
          footer={null}
          closable={false}
        >
          <div className="custom-modal-container">
            <h2 className="custom-modal-title">Are you sure?</h2>
            <p className="custom-modal-message">
              This action cannot be undone.
            </p>
            <div className="custom-modal-buttons">
              <button
                className="custom-modal-button"
                onClick={() =>
                  setOverlayState((prev) => ({
                    ...prev,
                    showDeleteAlert: false,
                  }))
                }
              >
                Cancel
              </button>
              <button
                className="custom-modal-button"
                onClick={handleDeleteFile}
                disabled={overlayState.isDeleting}
              >
                {overlayState.isDeleting ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default FileCard;
