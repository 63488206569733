import {
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLoading,
  IonRow,
  IonTitle,
  IonToolbar,
  IonCol,
} from "@ionic/react";
import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Crop, Save } from "react-feather";
import useSaveSVG from "../../../hooks/useSaveSvg";
import AutoUploadImage from "../../Converter/components/AutoUploadImage";
import useSvgUpdater from "../SvgUpdater";
import Canvas from "./Canvas";
import Close from "./Close/Close";
import CropImage from "./CropSvg";
import ExportFile from "./ExportFile";
import LayerSelector from "./LayerSelector";
import ToolBar from "./ToolBar";
import TopBar from "./TopBar";
import Tutorial from "./Tutorial";
import { faArrowUpFromBracket, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SvgEditorMobile = () => {
  const {
    svg,
    svgJoined,
    undoActions,
    redoActions,
    colors,
    changesMade,
    setChangesMade,
    setColors,
    updateSvg,
    undo,
    redo,
    currentFile,
  } = useSvgUpdater();

  const [cursor, setCursor] = useState(`crosshair`);
  const [layerSelected, setLayerSelected] = useState("all");
  const [toolbarState, setToolbarState] = useState("default");
  const [toolSelected, setToolSelected] = useState("move");
  const [eraserRadius, setEraserRadius] = useState(25);
  const [vaccumRadius, setVaccumRadius] = useState(25);

  const [file, setFile] = useState("");
  const [originalDimensions, setOriginalDimensions] = useState(undefined);
  const [isCropVisible, setIsCropVisible] = useState(false);
  const [largeImageWarning, setLargeImageWarning] = useState(false);

  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: null,
    y: null,
    width: null,
    height: null,
  });

  const { saveSVG, loading: savingInProgress } = useSaveSVG(
    svgJoined,
    currentFile
  );

  useEffect(() => {
    if (layerSelected === "all") {
      setToolbarState("default");
      setToolSelected("move");
    }
  }, [layerSelected]);

  return (
    <>
      <IonLoading isOpen={savingInProgress} message={"Saving..."} />
      <AutoUploadImage
        setOriginalDimensions={setOriginalDimensions}
        setCrop={setCrop}
        setLargeImageWarning={setLargeImageWarning}
        setFile={setFile}
        setColors={setColors}
        layerSelected={layerSelected}
      />

      <IonHeader>
        <IonToolbar>
          <IonGrid>
            <IonRow class="ion-align-items-center ion-justify-content-between">
              {/* Left Section */}
              <IonCol size="auto">
                <Close
                  changesMade={changesMade}
                  svgJoined={svgJoined}
                  currentFile={currentFile}
                >
                  <FontAwesomeIcon icon={faX} style={{ marginLeft: "10px" }} />
                </Close>
              </IonCol>

              {/* Center Section */}
              <IonCol class="ion-text-center">
                <IonTitle class="ion-text-wrap">Editor</IonTitle>
              </IonCol>

              {/* Right Section */}
              <IonCol size="auto" style={{ margin: "auto" }}>
                <IonRow class="ion-align-items-center ion-justify-content-end">
                  <TopBar
                    undo={undo}
                    redo={redo}
                    undoActions={undoActions}
                    redoActions={redoActions}
                  />

                  <Button
                    type="text"
                    disabled={!changesMade || savingInProgress}
                    onClick={async () => {
                      await saveSVG();
                      setChangesMade(false);
                    }}
                  >
                    <IonRow class="ion-justify-content-center">
                      <Save size={14} />
                    </IonRow>
                  </Button>

                  <Button
                    type="text"
                    onClick={() => {
                      document.getElementById("AutoConvertUpload").click();
                      setIsCropVisible(true);
                    }}
                  >
                    <IonRow class="ion-justify-content-center">
                      <Crop size={14} />
                    </IonRow>
                  </Button>

                  <Tutorial svgJoined={svgJoined} />

                  <ExportFile svgJoined={svgJoined}>
                    <IonRow class="ion-justify-content-center">
                      <FontAwesomeIcon
                        icon={faArrowUpFromBracket}
                        size={"sm"}
                      />
                    </IonRow>
                    <div style={{ fontSize: "10px" }}>Export</div>
                  </ExportFile>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonGrid id="middleBox" style={{ height: "100%", padding: "0px" }}>
          <IonRow style={{ height: "100%" }}>
            <Col
              flex="auto"
              style={{
                backgroundColor: "#f0f0f0",
                height: "calc(100%)",
                overflowX: "scroll",
                cursor: cursor,
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <div style={{ height: "100%" }}>
                <Canvas
                  svg={svg}
                  svgJoined={svgJoined}
                  setSvg={updateSvg}
                  layerSelected={layerSelected}
                  toolSelected={toolSelected}
                  eraserRadius={eraserRadius}
                  vaccumRadius={vaccumRadius}
                  setCursor={setCursor}
                />

                <CropImage
                  originalDimensions={originalDimensions}
                  setOriginalDimensions={setOriginalDimensions}
                  cropGlobal={crop}
                  setCropGlobal={setCrop}
                  largeImageWarning={largeImageWarning}
                  file={file}
                  isModalVisible={isCropVisible}
                  setIsModalVisible={setIsCropVisible}
                  updateSvg={updateSvg}
                  svg={svg}
                />
              </div>
            </Col>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <Row
            justify="center"
            style={{ paddingTop: "5px", paddingBottom: "5px", width: "100%" }}
          >
            {colors && (
              <LayerSelector
                setLayerSelected={setLayerSelected}
                colors={colors}
                layerSelected={layerSelected}
              />
            )}
          </Row>
          <ToolBar
            colors={colors}
            layerSelected={layerSelected}
            setColors={setColors}
            setLayerSelected={setLayerSelected}
            setToolSelected={setToolSelected}
            setToolbarState={setToolbarState}
            svg={svg}
            toolSelected={toolSelected}
            toolbarState={toolbarState}
            updateSvg={updateSvg}
            // radius={radius}
            // setRadius={setRadius}
            eraserRadius={eraserRadius}
            vaccumRadius={vaccumRadius}
            setEraserRadius={setEraserRadius}
            setVaccumRadius={setVaccumRadius}
          />
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default SvgEditorMobile;
