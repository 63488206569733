import * as Sentry from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";

export const initSentry = () => {
  Sentry.init(
    {
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllInputs: false,
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment:
        window.location.hostname === "localhost" ? "development" : "production",
    },
    // Forward the init method from @sentry/react
    SentryReact.init
  );
};
