import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { faCheckCircle, faLockKeyhole } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import laptop from "../../assets/laptopImage.png";
import AuthHeader from "../Auth/AuthHeader";
import SubmitButton from "../Auth/SubmitButton";
import PlanSelection from "./PlanSelection";
import "./styles.css";
import { Switch } from "antd";
import { createCheckoutSession } from "../../lib/stripe/createCheckoutSession"; // Import the function
import {  signOutUser } from "../../lib/firebase/firebase";
import { PLANS } from "./constants"; // Import the PLANS
import { Button } from "antd";
import { Tag } from "antd"; // Import the Tag component
import "./Checkout.css";

const benefitsArray = [
  "Full Access to SVG Editor.",
  "Unlimited AI Enhanced Converts.",
  // "Unlimted SVG Storage.",
  "Commercial Use Image Generator.",
  "Bulk SVG Conversion.",
  "AI Image Resizer.",
];


const LifeTimePro = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1000);
  const [isShortView, setIsShortView] = useState(window.innerHeight < 800);
  const [paymentPlan, setPaymentPlan] = useState("yearly");
  const { user, userIsPremium } = useAuth();
  const [loading, setLoading] = useState(false);
  // const [selectedPlan, setSelectedPlan] = useState("yearly");


  const handlePlanChange = (plan) => {
    setPaymentPlan(plan);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1000);
      setIsShortView(window.innerHeight < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleUpgrade = async (subscriptionType) => {
    setLoading(true);
    await createCheckoutSession(user.uid, subscriptionType);
    setLoading(false);
  };

  const handleCheckout = async () => {
    const plan = PLANS.find((p) => p.type === paymentPlan);
    handleUpgrade(plan.subscriptionType);


  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      await signOutUser();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Check if the user's email is verified
  if (!user || !user.emailVerified) {
    return null; // or render a different component/message
  }

  return (
    <div className="Pro_box">
      <div className={`inner_container ${isMobileView ? "full_width" : ""}`}>
        {!isMobileView && (
          <div className="left">
            {!isShortView && (
              <div>
                <AuthHeader flexStart={true} />
              </div>
            )}
            <img src={laptop} alt="" />
          </div>
        )}
        <div className="right">
          {isMobileView && (
            <div>
              <AuthHeader flexStart={true} />
            </div>
          )}
          <div className="top">
            <h3>Get Access to SvgTrace Pro</h3>
            <Button 
              type="text" 
              onClick={handleLogout} 
              style={{ 
                background: 'none', 
                border: 'none', 
                color: '#007bff', 
                textDecoration: 'underline', 
                cursor: 'pointer' 
              }}
            >
              Log Out
            </Button> 

          </div>
          <p className="paragraph">
            Save money and time getting SVG files.
          </p>
          <div className="bnfts">
            {benefitsArray.map((data) => (
              <div className="bnf" key={data}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#44C4A1"
                  fontSize={"20px"}
                />
                <p className="paragraph">{data}</p>
              </div>
            ))}
          </div>
          <PlanSelection
                selectedPlan={paymentPlan}
                onPlanSelect={setPaymentPlan}
              />

          <SubmitButton loading={loading} title={"Checkout"} onClick={handleCheckout} />
        </div>
      </div>
    </div>
  );
};

export default LifeTimePro;
