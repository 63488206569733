// Helper function to generate square points
export const generateSquarePoints = (
  centerX,
  centerY,
  halfLength,
  numDivisions
) => {
  const topLeftX = centerX - halfLength;
  const topLeftY = centerY - halfLength;
  const topRightX = centerX + halfLength;
  const bottomRightY = centerY + halfLength;

  const points = [];
  for (let i = 0; i < numDivisions; i++) {
    points.push([
      topLeftX + ((topRightX - topLeftX) / (numDivisions - 1)) * i,
      topLeftY,
    ]);
    points.push([
      topRightX,
      topLeftY + ((bottomRightY - topLeftY) / (numDivisions - 1)) * i,
    ]);
    points.push([
      topRightX - ((topRightX - topRightX) / (numDivisions - 1)) * i,
      bottomRightY,
    ]);
    points.push([
      topLeftX,
      bottomRightY - ((bottomRightY - topLeftY) / (numDivisions - 1)) * i,
    ]);
  }
  return points;
};
