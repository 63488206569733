import { useEffect } from "react";
import { Preferences } from "@capacitor/preferences";
import { StatusBar, Style } from "@capacitor/status-bar";
import {
  IonApp,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonTabs,
  isPlatform,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { RateApp } from "capacitor-rate-app";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import "./App.css";
import Home from "./pages/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import { AuthProvider } from "./contexts/AuthContext";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import PrivateRoute from "./pages/Auth/PrivateRoute";
import SignIn from "./pages/Auth/SignIn";
import SignUp from "./pages/Auth/SignUp";
import "./theme/variables.css";
import Checkout from "./pages/Checkout";
import { store } from "./redux/store";

setupIonicReact();

const App = () => {
  useEffect(() => {
    if (!(isPlatform("ios") && !isPlatform("mobileweb"))) {
      ReactGA.initialize("G-YTYW71WQNH");
    }
    if (isPlatform("android")) {
      StatusBar.setStyle({ style: Style.Dark }).catch(() => {
        console.log("StatusBar not available on web");
      });
    } else if (
      (isPlatform("ios") || isPlatform("ipad") || isPlatform("iphone")) &&
      !isPlatform("mobileweb")
    ) {
      StatusBar.setStyle({ style: Style.Light }).catch(() => {
        console.log("StatusBar not available on web");
      });
      StatusBar.setOverlaysWebView({ overlay: false }).catch(() => {
        console.log("StatusBar overlay configuration not available on web");
      });
      checkAPPLaunchEvent();
    }
  }, []);

  const checkAPPLaunchEvent = async () => {
    try {
      const result = await Preferences.get({ key: "launchCount" });
      let launchCount = Number(result.value) || 0;
      console.log("Launch count:", launchCount);

      if (launchCount >= 10) {
        handleRequestReview();
        launchCount = 0;
        await Preferences.set({
          key: "launchCount",
          value: launchCount.toString(),
        });
      } else {
        launchCount++;
        await Preferences.set({
          key: "launchCount",
          value: launchCount.toString(),
        });
      }
    } catch (error) {
      console.error("Error accessing preferences:", error);
    }
  };

  const handleRequestReview = async () => {
    try {
      await RateApp.requestReview().then((res) =>
        console.log("response =====>", res)
      );
    } catch (error) {
      console.log(error);
      alert(`Review request failed: ${(error as any)?.message}`);
    }
  };

  if (isPlatform("ios") && !isPlatform("mobileweb")) {
    return (
      <Provider store={store}>
        <IonApp>
          <IonReactRouter>
            <IonTabs>
              <IonRouterOutlet
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/">
                  <Redirect to="/" />
                </Route>
              </IonRouterOutlet>
              <IonTabBar
                hidden
                id="tabBar"
                slot="bottom"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <IonTabButton tab="Home" href="/"></IonTabButton>
              </IonTabBar>
            </IonTabs>
          </IonReactRouter>
        </IonApp>
      </Provider>
    );
  } else {
    return (
      <Provider store={store}>
        <IonApp>
          <AuthProvider>
            <IonReactRouter>
              <IonTabs>
                <IonRouterOutlet
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <Redirect exact path="/" to="/home" />
                  <Route exact path="/signup" component={SignUp} />
                  <Route exact path="/signin" component={SignIn} />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route exact path="/checkout" component={Checkout} />
                  <PrivateRoute exact path="/home" component={Home} />
                </IonRouterOutlet>
                <IonTabBar
                  hidden
                  id="tabBar"
                  slot="top"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <IonTabButton tab="home" href="/home">
                    <IonLabel
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    >
                      Home
                    </IonLabel>
                  </IonTabButton>
                </IonTabBar>
              </IonTabs>
            </IonReactRouter>
          </AuthProvider>
        </IonApp>
      </Provider>
    );
  }
};

export default App;
